<template>
    <div id="content" class="config">
        <div class="list-container">
            <div class="list" v-for="(index, k) in tools" :key="k" v-if="index && (typeof index.visible === 'undefined' || index.visible) && index.id == 'navSettings'">
                <!-- PARENT ITEM -->
                <!-- Se hace un v-if para que solo muestre los subitems de configuración que es donde está todo el submenú de configuracion -->
                <!-- Título de cada apartado del menú ej: Librería-->
                <div :id="item.id" class="section" v-for="(item, j) in index.access" :key="j" v-if="item" @click="item.href ? navTool(item.href) : ''">
                    <span class="titl">{{ $t(item.name) }}</span>

                    <!-- Todos los subitems ej:Recursos y Categorías -->
                    <div class="item-list">
                        <div class="item" v-for="(subitem, l) in item.access" :key="l" v-if="(subitem.visible && subitem.href != '/availability') || (productAvailability && subitem.href == '/availability')" @click="navTool(subitem.href)">
                            {{ $t(subitem.name) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Config',
    data() {
        return {
            currentPage: 'configuration'
        }
    },
    computed: {
        productAvailability() {
            return parseInt(this.$store.getters['login/getProductAvailability']) == 1
        },
        tools() {
            if (this.userRole) {
                return this.$store.getters['getToolsByRoleLevel'](this.userRole)
            }
        },
        userRole() {
            var user = this.$store.getters['login/getLocalEmployee']
            var roles = this.$store.getters['employee/getRoles']()
            // console.log(Object.keys(roles));
            if (user && Object.keys(roles).length > 0) {
                // console.log(roles[user.rol]);
                return parseInt(roles[user.rol].level)
            }
        }
    },
    methods: {
        togglePage(where) {
            if (where != this.currentPage) {
                this.currentPage = where
            } else {
                this.currentPage = 'configuration'
            }
        },
        navTool(path) {
            let self = this

            if (path.includes('http')) {
                window.open(path, '_blank')
            } else {
                self.$router.push(self.redirectPath(path)).catch((err) => {
                    if (err.name == 'NavigationDuplicated') {
                        self.$router.push('/reset' + self.redirectPath(path))
                    }
                })
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        }
    },
    created() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.config {
    @include display-flex();
    @include align-items(flex-start);

    @include justify-content(center);
    padding: 20px;
    height: calc(100% - 80px) !important;
    max-height: calc(100% - 80px) !important;

    .list-container {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
    }

    .list {
        display: grid;
        // grid-template-columns: 31% 31% 31%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto;
        // grid-gap: 9px;
        gap: 30px;
        grid-auto-flow: row;
        align-items: start;

        // width: 100%;
        min-width: fit-content;
        width: 80%;
        max-width: 1500px;

        .section {
            @include border-radius(6px);
            background-color: $color-white;
            width: auto;
            // min-width: 80%;
            min-width: fit-content;
            max-width: 340px;
            padding: 12px 18px;

            .titl {
                @include display-flex();
                @include align-items(center);
                @include justify-content(flex-start);
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
                width: 100%;
                height: 40px;

                &:before {
                    @include border-radius(4px);
                    @include background($color: $color-primary-500, $image: img('menu_register_ffffff.svg'), $size: 20px);
                    content: '';
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    margin-right: 9px;
                }
            }

            .item-list {
                @include display-flex();
                @include align-items(flex-start);
                @include justify-content(flex-start);
                @include flex-direction(column);
                @include flex-wrap(wrap);

                .item {
                    @include border-radius(4px);
                    @include font-size(ml);
                    font-family: $text-medium;
                    color: $color-black;
                    display: inline-block;
                    width: 100%;
                    padding: 9px 0 9px 15px;
                    margin-right: 15px;
                    cursor: pointer;
                    transition-duration: 0.2s;

                    &:hover {
                        background-color: $color-neutral-200;
                    }
                }
            }

            &#navTemplate .titl:before {
                background-image: img('menu_register_ffffff.svg');
            }
            &#navLibrary .titl:before {
                background-image: img('menu_library_ffffff.svg');
            }
            &#navProduct .titl:before {
                background-image: img('menu_label_ffffff.svg');
            }
            &#navSites .titl:before {
                background-image: img('location_ffffff.svg');
            }
            &#navUsers .titl:before {
                background-image: img('user_ffffff.svg');
            }
            &#navOrganization .titl:before {
                background-image: img('menu_organization_ffffff.svg');
            }
        }
    }
}
</style>
